﻿/**
*   RyanCV - Resume/CV/vCard Theme (Jekyll)
*   Version: 1.0
*   Author: beshleyua
*   Author URL: https://themeforest.net/user/beshleyua
*   Copyright © RyanCV by beshleyua. All Rights Reserved.
**/

/*
	Colors
*/

$active-color: #78cc6d; /* active theme color */
$dark-color: #323232; /* primary theme color */
$default-color: #646464; /* secondary theme color */

$bg: #fff;
$extra-color: #999;
$light-color: #d8dbe2;
$white-color: #FFF;
$extra-active-color: rgba($active-color, 0.4);
$extra2-active-color: rgba($active-color, 0.01);
$error-color: red;

/*
	Fonts
*/
$default-font: 'Poppins';
$icons-font: 'Ionicons';

/*
	Fonts Size
*/
$large-size: 17px;
$default-size: 16px;
$small-size: 13px;
$extra-small-size: 14px;
$extra2-small-size: 11px;

$title-size: 32px;
$subtitle-size: 14px;

$h1-size: 30px;
$h2-size: 24px;
$h3-size: 22px;
$h4-size: 20px;
$h5-size: 18px;
$h6-size: 16px;

/*
	Icons Size
*/
$large-icon-size: 32px;
$default-icon-size: 20px;
$small-icon-size: 16px;

/*
	Letter-Spacing
*/
$default-letter-spacing: 0em;
$extra-letter-spacing: 0.04em;

/*
	Line Height
*/
$line-height: 1.8em;

/*
	Forms
*/
$form-border: 1px solid $light-color;
$form-border-hover: 1px solid $active-color;

/*
	Params
*/
@mixin border-radius($radius) {
	 border-radius: $radius;
	 -moz-border-radius: $radius;
	 -webkit-border-radius: $radius;
	 -khtml-border-radius: $radius;
}

@mixin transition($param){
	 transition: $param;
	 -moz-transition: $param;
	 -webkit-transition: $param;
	 -o-transition: $param;
}

@mixin transform($param) {
	 transform: $param;
	 -webkit-transform: $param;
	 -moz-transform: $param;
	 -o-transform: $param;
}

@mixin box-shadow($param){
	 box-shadow: $param;
	 -moz-box-shadow: $param;
	 -webkit-box-shadow: $param;
	 -khtml-box-shadow: $param;
}